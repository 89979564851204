import * as React from "react";
import { Chip, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import FaceIcon from "@mui/icons-material/Face";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useDialplanEditorContext } from "./DialplanEditorContext";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";


function AddDialTarget({ targets, setTargets }) {
  const [targetToAdd, setTargetToAdd] = React.useState(undefined);
  const [delay, setDelay] = React.useState(0);

  const { dialplan } = useDialplanEditorContext();
  const { allowedDialTargets } = dialplan;

  const addTarget = (target, delay) => {
    setTargets([...targets, { ...target, delay }]);

    // reset modal
    setTargetToAdd(undefined);
    setDelay(0);
  };

  if (!allowedDialTargets || allowedDialTargets.length === 0) {
    return (<>
        <Grid container sx={{
          minWidth: 120,
          justifyContent: "center",
          alignContent: "center",
          width: "100%"
        }}>
          <Typography variant="body" sx={{ color: "red" }}>
            ⚠️ Contatta il supporto per configurare i numeri dei collaboratori
          </Typography>
        </Grid>
      </>
    );
  }

  const placeableNumberTargets = allowedDialTargets.filter(target =>  !!target.number && !targets.some(t => t.number === target.number));
  const placeableDeviceTargets = allowedDialTargets.filter(target => !!target.id && !targets.some(t => t.id === target.id));


  if (placeableNumberTargets.length === 0 && placeableDeviceTargets.length === 0) {
    return null;
  }

  return (<>
    <Stack>
      <Typography variant="body">
        Seleziona un collaboratore:
      </Typography>
      <Grid container spacing={1} align={"center"}>
        {[...placeableNumberTargets, ...placeableDeviceTargets].map((target, index) => (<Grid item xs={12} sm={4} md={4} sx={{ mt: 2 }} key={index}>
          <DialTarget target={target} onClick={() => setTargetToAdd(target)}/>
        </Grid>))}
      </Grid>
    </Stack>
    <Dialog open={!!targetToAdd} onClose={() => setTargetToAdd(undefined)}>
      <DialogTitle>Inoltra ad un collaboratore</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Dopo quanto tempo deve squillare il telefono di <b>{targetToAdd?.name}</b>?
        </DialogContentText>
        <FormControl fullWidth>
          <Select
            autoFocus
            value={delay}
            label="Tempo massimo di attesa"
            onChange={event => setDelay(event.target.value)}
            fullWidtd
            sx={{ mt: 2 }}>
            <MenuItem value={0}>Immediatamente</MenuItem>
            <MenuItem value={10}>10 secondi</MenuItem>
            <MenuItem value={20}>20 secondi</MenuItem>
            <MenuItem value={30}>30 secondi</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setTargetToAdd(undefined)}>Cancella</Button>
        <Button onClick={() => addTarget(targetToAdd, delay)}>Conferma</Button>
      </DialogActions>
    </Dialog>
  </>);
}

function DialTarget({ target, onClick }) {
  return (<Chip icon={<FaceIcon/>}
                label={target.name}
                variant="outlined"
                color="default"
                onClick={onClick}/>);
}

export default AddDialTarget;
