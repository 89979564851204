import React, { createContext, useContext, useState } from 'react';

const AudioPlayerContext = createContext();

export function AudioPlayerProvider({ children }) {
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentPlayingUrl, setCurrentPlayingUrl] = useState(null);

  const playAudio = (url) => {
    // Stop current audio if playing
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }

    // Create and play new audio
    const audio = new Audio(url);
    audio.onplay = () => {
      setIsPlaying(true);
      setCurrentPlayingUrl(url);
    };
    audio.onended = () => {
      setCurrentAudio(null);
      setIsPlaying(false);
      setCurrentPlayingUrl(null);
    };
    audio.onerror = () => {
      console.error('Audio playback error');
      setCurrentAudio(null);
      setIsPlaying(false);
      setCurrentPlayingUrl(null);
    };
    audio.onpause = () => {
      setIsPlaying(false);
      setCurrentPlayingUrl(null);
    };
    
    // Handle Android playback issues with promise-based approach
    try {
      const playPromise = audio.play();
      
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Playback started successfully
            setCurrentAudio(audio);
          })
          .catch(error => {
            console.error('Audio playback failed:', error);
            setIsPlaying(false);
            setCurrentPlayingUrl(null);
          });
      } else {
        // Fallback for browsers not supporting promises
        setCurrentAudio(audio);
      }
    } catch (error) {
      console.error('Audio playback exception:', error);
      setIsPlaying(false);
      setCurrentPlayingUrl(null);
    }
  };

  const stopAudio = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
      setIsPlaying(false);
      setCurrentPlayingUrl(null);
    }
  };

  const isUrlPlaying = (url) => {
    return isPlaying && currentPlayingUrl === url;
  };

  return (
    <AudioPlayerContext.Provider value={{ playAudio, stopAudio, isPlaying, isUrlPlaying, currentPlayingUrl }}>
      {children}
    </AudioPlayerContext.Provider>
  );
}

export function useAudioPlayer() {
  const context = useContext(AudioPlayerContext);
  if (!context) {
    throw new Error('useAudioPlayer must be used within an AudioPlayerProvider');
  }
  return context;
} 