import * as React from 'react';
import {Box, Button, Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import TimeIntervalsPicker from "./TimeIntervalsPicker";
import DialplanNode, {DialplanNodeIndexContext, DialplanNodePropContext, useDialplanNodeContext} from "./DialplanNode";
import Accordion from "../Accordion";
import Paper from "@mui/material/Paper";
import AddTimeIntervals from "./AddTimeIntervals";
import {useDialplanEditorContext} from './DialplanEditorContext';
import Hangup from "./HangupNode";
import TtsAnnounce from "./TtsAnnounce";

function TimeSwitchNode() {
    const {node: {cases}} = useDialplanNodeContext();
    const {isEditMode} = useDialplanEditorContext();
    const [openAccordion, setOpenAccordion] = React.useState(false);
    const lastCaseRef = React.useRef(null);
    
    return (
        <Grid container direction={"column"}>
            <Grid item xs={12}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <Typography variant="h6" align="left">
                        ⏲️ Smistamento orario
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} sx={{mt: 2}}>
                <Typography variant="body" display={'inline'}>
                    Determina il flusso di risposta in base al giorno e orario della chiamata.
                </Typography>
            </Grid>
            <DialplanNodePropContext prop={"cases"}>
                {isEditMode && <AddTimeSwitchNodeCase lastCaseRef={lastCaseRef} />}
                <Grid item container sx={{mt: 2,}}>
                    {cases.map((it, index) => (
                            <DialplanNodeIndexContext key={index} index={index}>
                                <TimeSwitchNodeCase ref={index === cases.length - 1 ? lastCaseRef : null} />
                            </DialplanNodeIndexContext>
                        )
                    )}
                    {cases.length === 0 && !isEditMode && <Typography>
                        Al momento non sono presenti casistiche basate sull'orario, modifica il piano di risposta per
                        aggiungerle
                    </Typography>}

                </Grid>
            </DialplanNodePropContext>
            <Grid item container sx={{mt: 4}}>
                <Accordion
                    title={"🔄"}
                    description={"Risposta predefinita: In tutti gli altri casi"}
                    expanded={openAccordion}
                    onChange={() => setOpenAccordion(!openAccordion)}>
                    <DialplanNode prop="default"/>
                </Accordion>
            </Grid>
        </Grid>
    )
}


export function AddTimeSwitchNodeCase({ lastCaseRef }) {
    const {node: cases, setNode} = useDialplanNodeContext();
    const addNode = () => {
        setNode([...cases, {intervals: [], node: Hangup.default}]);
        // Scroll alla nuova casistica dopo il render
        setTimeout(() => {
            if (lastCaseRef && lastCaseRef.current) {
                lastCaseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 100);
    }
    return <Grid
        onClick={addNode}
        container
        sx={{
            mt: 2,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 48,
            backgroundColor: "#e3f2fd",
            borderRadius: 2,
            border: "1px solid #90caf9",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "#bbdefb"
            },
        }}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", py: 1 }}>
            <Typography variant="button" sx={{color: "#1976d2", fontWeight: 500}}>
                + Aggiungi casistica
            </Typography>
            <Typography variant="caption" sx={{color: "#5c6bc0", fontSize: 11}}>
                Definisci quando e come rispondere
            </Typography>
        </Box>
    </Grid>
}

const TimeSwitchNodeCase = React.forwardRef((props, ref) => {
    const {node, setNode, setNodeChild} = useDialplanNodeContext();
    const {isEditMode} = useDialplanEditorContext();
    const [openAccordion, setOpenAccordion] = React.useState(false);
    return (
        <Stack sx={{mt: 2, width: "100%"}} ref={ref}>
            <Paper elevation={3}>
                <Grid item container sx={{mt: 1, p: 1}}>
                    {isEditMode && <Stack flex={1} direction="row" justifyContent={'flex-end'}>
                        <Button color="error" onClick={() => setNode(null)}>
                            Rimuovi casistica
                        </Button>
                    </Stack>}
                    <TimeIntervalsPicker intervals={node?.intervals || []}
                                         setIntervals={setNodeChild("intervals")}
                                         isEditMode={isEditMode}
                                         showDayOff={false}/>
                    {isEditMode && <AddTimeIntervals intervals={node.intervals}
                                                     setIntervals={setNodeChild("intervals")}
                                                     daysLabel={"Seleziona i giorni in cui applicare questo intervallo"}
                                                     startTimeLabel={"Seleziona l'orario di inizio"}
                                                     endTimeLabel={"Seleziona l'orario di fine"}
                                                     sx={{mt: 2}}/>
                    }
                </Grid>
                <Grid item container sx={{mt: 4}}>
                    <Accordion title={"🟦"}
                               description={"Configura la risposta per questa casistica oraria"}
                               expanded={openAccordion}
                               onChange={() => setOpenAccordion(!openAccordion)}>
                        <DialplanNode prop="node"/>
                    </Accordion>
                </Grid>
            </Paper>
        </Stack>
    );
});

TimeSwitchNode.title = "Smistamento orario"
TimeSwitchNode.description = "Rispondi in base al giorno e orario della chiamata."
TimeSwitchNode.default = {
    type: "TIME_SWITCH",
    cases: [],
    default: TtsAnnounce.default
}

export default TimeSwitchNode;
