import * as React from "react";
import { Grid, Paper, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import TimeIntervalsPicker from "./TimeIntervalsPicker";
import DialplanNode, { useDialplanNodeContext } from "./DialplanNode";
import Accordion from "../Accordion";
import AddTimeIntervals from "./AddTimeIntervals";
import { useDialplanEditorContext } from "./DialplanEditorContext";
import Hangup from "./HangupNode";
import TtsAnnounce from "./TtsAnnounce";

function OpeningTimeNode() {
  const { isEditMode } = useDialplanEditorContext();
  const { node, setNodeChild } = useDialplanNodeContext();
  const [openAccordion, setOpenAccordion] = React.useState(false);
  const { intervals } = node;

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <div style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}>
          <Typography variant="h6" align="left">
            🕑 Orari di apertura
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <Typography variant="body1">
          Configura gli orari di apertura della tua attività e definisci come gestire le chiamate in entrambi i casi.
        </Typography>
      </Grid>

      <Grid item container sx={{ mt: 2 }}>
        <Paper elevation={3} sx={{ width: "100%", p: 2 }}>
          <TimeIntervalsPicker
            intervals={intervals}
            setIntervals={setNodeChild("intervals")}
            showDayOff={true}
            isEditMode={isEditMode}
          />

          {isEditMode && (
            <AddTimeIntervals
              intervals={intervals}
              setIntervals={setNodeChild("intervals")}
              daysLabel="Seleziona i giorni di apertura"
              startTimeLabel="Seleziona l'orario di apertura"
              endTimeLabel="Seleziona l'orario di chiusura"
              sx={{ mt: 2 }}
            />
          )}
        </Paper>
      </Grid>

      <Grid item container sx={{ mt: 4 }}>
        <Stack spacing={2} width="100%">
          <Accordion
            title="✅"
            description="Configura la risposta quando l'attività è aperta"
            expanded={openAccordion === "open"}
            onChange={() => setOpenAccordion(openAccordion === "open" ? false : "open")}
          >
            <DialplanNode prop="open"/>
          </Accordion>

          <Accordion
            title="😴"
            description="Configura la risposta quando l'attività è chiusa"
            expanded={openAccordion === "closed"}
            onChange={() => setOpenAccordion(openAccordion === "closed" ? false : "closed")}
          >
            <DialplanNode prop="closed"/>
          </Accordion>
        </Stack>
      </Grid>
    </Grid>
  );
}

OpeningTimeNode.description = "Gestisci le chiamate in base agli orari di apertura della tua attività";
OpeningTimeNode.title = "Orari di apertura";
OpeningTimeNode.default = {
  type: "OPENING_TIME",
  open: { ...TtsAnnounce.default, text: "Siamo aperti, come posso aiutarti?", next: Hangup.default },
  closed: { ...TtsAnnounce.default, text: "In questo momento siamo chiusi, lascia un messaggio dopo il beep grazie.", },
  intervals: []
};

export default OpeningTimeNode;
