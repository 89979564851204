import { useMutation } from '@tanstack/react-query';
import { BASE_API_URL } from "../Config";
import axios from "axios";
import useAuthentication from "./AuthenticationService";
import { toast } from 'react-toastify';

const instance = axios.create({
    timeout: 30_000,
    headers: {
        "Content-Type": "application/json",
    }
});

const generateTtsPreview = async ({ text, voiceName, token }) => {
    const response = await instance.post(BASE_API_URL + "/voicemail-config/tts-demo", {
        text,
        voiceName
    }, {
        headers: {
            "Authorization": "Bearer " + token
        }
    });

    return response.data;
};

export const useTtsPreview = () => {
    const authentication = useAuthentication();

    return useMutation({
        mutationFn: ({ text, voiceName }) => generateTtsPreview({ 
            text, 
            voiceName, 
            token: authentication.token 
        }),
        onError: (error) => {
            toast.error(error.response?.data?.localizedMessage || 'Errore durante la generazione della preview', {
                position: "bottom-center",
                autoClose: 3000
            });
        }
    });
}; 