import * as React from "react";
import { Grid } from "@mui/material";
import DownArrow from "../DownArrow";
import DialplanNode, { useDialplanNodeContext } from "./DialplanNode";
import { useDialplanEditorContext } from "./DialplanEditorContext";
import Record from "./Record";
import AnnounceBox from "./AnnounceBox";

function TtsAnnounce() {
  const { node, setNode } = useDialplanNodeContext();
  const { isEditMode } = useDialplanEditorContext();

  const onTextChange = (event) => {
    setNode({ ...node, text: event.target.value });
  };
  const onVoiceChange = (event) => {
    setNode({ ...node, voiceName: event.target.value });
  };

  return (
    <>
      <Grid container direction={"column"}>
        <AnnounceBox
          title="📣 Annuncio vocale"
          description="Riproduci un annuncio vocale personalizzato con la voce del tuo assistente virtuale."
          isEditMode={isEditMode}
          text={node.text}
          onTextChange={onTextChange}
          wavPlayUrl={node.wavPlayUrl}
          voiceName={node.voiceName}
          onVoiceChange={onVoiceChange}
        />
      </Grid>
      <DownArrow/>
      <DialplanNode prop={"next"}/>
    </>
  );
}

TtsAnnounce.description = "Riproduci un annuncio vocale personalizzato con la voce del tuo assistente virtuale.";
TtsAnnounce.title = "Riproduci annuncio vocale";
TtsAnnounce.default = {
  type: "TTS_ANNOUNCE",
  text: "Al momento non possiamo rispondere, lascia un messaggio dopo il beep grazie.",
  voiceName: "ISABELLA",
  next: Record.default
};

export default TtsAnnounce;
