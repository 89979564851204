import { Grid, Paper, Stack, useMediaQuery, useTheme } from "@mui/material";
import Container from "@mui/material/Container";
import { useConfirm } from "material-ui-confirm";
import * as React from "react";
import { toast } from "react-toastify";

import { DialplanEditorContext, useDialplanEditorContext } from "./dialplan/DialplanEditorContext";
import DialplanNode, { DialplanRootNode } from "./dialplan/DialplanNode";
import DialplanEditBar from "./DialplanEditBar";
import Box from "@mui/material/Box";
import { AudioPlayerProvider } from "./dialplan/AudioPlayerContext";

function DialplanBoxWithContext({ dialplan, setDialplan }) {
  return (
    <DialplanEditorContext dialplan={dialplan}>
      <DialplanBox onSave={setDialplan}/>
    </DialplanEditorContext>
  );
}

function DialplanBox({ onSave }) {
  const confirm = useConfirm();
  const { dialplan, isEditMode, setIsEditMode } = useDialplanEditorContext();

  const handleSave = () => {
    return onSave(dialplan).then(() => {
      toast.success("🚀 Risponditore aggiornato ed attivato!", { position: "bottom-center", autoClose: 3000 });
      setIsEditMode(false);
    }).catch((e) => {
      const localizedTitle = e.response?.data?.localizedTitle || "😕 Accidenti!";
      const localizedMessage = e.response?.data?.localizedMessage || "Non riesco a salvare le tue modifiche. Riprova!";
      toast.error(`${localizedTitle}\n${localizedMessage}`,
        { position: "bottom-center", autoClose: 3000 });
      console.error(e);
    });
  };

  const onEditCancel = () => {
    confirm({
      title: "Annullare le modifiche senza salvare?",
      description: "Se esci dalla modalità modifica senza salvare le modifiche andranno perse.",
      cancellationText: "Annulla",
      confirmationText: "Esci senza salvare",
    })
      .then(() => {
        setIsEditMode(false);
      })
      .catch(() => {
        // stay in edit mode
      });
  };

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const ContainerComponent = isXsScreen ? Box : Paper;

  console.log("DialplanBox render", isXsScreen);

  return (
    <>
      <Container component="main" sx={{ mb: 12, px: "1rem" }} xs={12} sm={10}>
        <Stack direction="row" justifyContent="start" flex={1}>
        </Stack>
        <ContainerComponent variant="outlined" sx={{
          my: 2,
          p: { xs: 0, sm: 2 }
        }}>
          <AudioPlayerProvider>
            <Grid container direction={"column"}>
              <DialplanRootNode>
                <DialplanNode prop="start"/>
              </DialplanRootNode>
            </Grid>
          </AudioPlayerProvider>
        </ContainerComponent>
      </Container>
      <DialplanEditBar isEdit={isEditMode} onEdit={() => setIsEditMode(true)} onCancel={onEditCancel}
                       onSave={handleSave}/>
    </>
  );
}

export default DialplanBoxWithContext;
