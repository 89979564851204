import * as React from 'react';
import {Button, Chip, Grid, Stack, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import DialplanNode, {DialplanNodePropContext, useDialplanNodeContext} from "./DialplanNode";
import {useDialplanEditorContext} from "./DialplanEditorContext";
import Paper from "@mui/material/Paper";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import IconButton from '@mui/material/IconButton';
import { useAudioPlayer } from './AudioPlayerContext';

import Accordion from "../Accordion";
import Hangup from "./HangupNode";

import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';
import Filter6Icon from '@mui/icons-material/Filter6';
import Filter7Icon from '@mui/icons-material/Filter7';
import Filter8Icon from '@mui/icons-material/Filter8';
import Filter9Icon from '@mui/icons-material/Filter9';
import TwentyZeroMpIcon from '@mui/icons-material/TwentyZeroMp';
import AnnounceBox from "./AnnounceBox";

function IVRNode() {
    const {node: {pad}} = useDialplanNodeContext();
    const {isEditMode} = useDialplanEditorContext();

    return (
        <>
            <Grid container direction={"column"}>
                <DialplanNodePropContext prop={"announce"}>
                    <IVRAnnounceBox/>
                </DialplanNodePropContext>

                <Grid item xs={12} sx={{mt: 2}}>
                    <DialplanNodePropContext prop={"pad"}>
                        <Typography variant="body" display={'inline'}>
                            Configura la risposta in base al tasto premuto:
                        </Typography>

                        <Grid item container sx={{mb: 2}}>
                            {Object.keys(pad).map((padKey) => (
                                <IvrNodePadCase padKey={padKey}/>
                            ))}
                            {Object.keys(pad).length === 0 &&
                                <Grid container sx={{
                                    minWidth: 120,
                                    justifyContent: "center",
                                    alignContent: "center",
                                    width: "100%",
                                    mt: 4
                                }}>
                                    <Typography variant="subtitle1" sx={{color: "red"}}>
                                        Non è stato definito nessun flusso di risposta in base al tasto premuto
                                    </Typography>
                                </Grid>
                            }
                        </Grid>

                        {isEditMode && <AddPadNode/>}
                    </DialplanNodePropContext>
                </Grid>
            </Grid>
        </>
    )
}

function IVRAnnounceBox() {
    const {node, setNode} = useDialplanNodeContext();
    const {isEditMode} = useDialplanEditorContext();
    
    const onTextChange = (event) => {
        setNode({...node, text: event.target.value});
    }

    const onVoiceChange = (event) => {
        setNode({...node, voiceName: event.target.value});
    }

    return (
        <AnnounceBox
            title="📣 Menù IVR (Premi 1, premi 2, ...)"
            description="Riproduci un messaggio spiegando le possibili scelte ed il rispettivo tasto da premere."
            isEditMode={isEditMode}
            text={node.text}
            onTextChange={onTextChange}
            wavPlayUrl={node.wavPlayUrl}
            voiceName={node.voiceName}
            onVoiceChange={onVoiceChange}
        />
    );
}

function AddPadNode() {
    const {node, setNode} = useDialplanNodeContext();
    const addPad = (padKey) => {
        setNode({
            ...node,
            [padKey]: Hangup.default
        })
    }

    const missingPads = [1, 2, 3, 4, 5, 6, 7, 8, 9].filter(it => !Object.keys(node).includes(it.toString()));

    if (missingPads.length === 0) {
        return null;
    }

    return <Stack sx={{mb: 6, mt: 6}}>
        <Typography variant="body">
            Aggiungi una nuova risposta in base al tasto premuto:
        </Typography>
        <Grid container spacing={1} align={"center"}>
            {missingPads.map((padKey, index) => (
                <Grid item xs={12} sm={4} md={4} sx={{mt: 2}} key={index}>
                    <PadChip padKey={padKey} onClick={() => addPad(padKey)}/>
                </Grid>
            ))}
        </Grid>
    </Stack>
}

function PadChip({padKey, onClick}) {
    const IconsMap = {
        1: Filter1Icon,
        2: Filter2Icon,
        3: Filter3Icon,
        4: Filter4Icon,
        5: Filter5Icon,
        6: Filter6Icon,
        7: Filter7Icon,
        8: Filter8Icon,
        9: Filter9Icon,
    }
    const Icon = IconsMap[padKey] || TwentyZeroMpIcon;

    return (<Chip icon={<Icon/>}
                  label={`Quando premo ${padKey}`}
                  variant="outlined"
                  onClick={onClick}
                  sx={{p: 1}}
    />)
}

function IvrNodePadCase({padKey}) {
    const {node, setNode} = useDialplanNodeContext();
    const {isEditMode} = useDialplanEditorContext();
    const [openAccordion, setOpenAccordion] = React.useState(false);
    const removeSelf = () => setNode(Object.keys(node).reduce((acc, it) => {
        if (it === padKey) {
            return acc;
        }

        return {
            ...acc,
            [it]: node[it]
        }
    }, {}));

    return (
        <Stack sx={{mt: 2, width: "100%"}}>
            <Paper elevation={3}>
                <Grid item container>
                    {isEditMode && <Stack flex={1} direction="row" justifyContent={'flex-end'} sx={{p: 1}}>
                        <Button color="error" onClick={removeSelf}>
                            Elimina questa risposta
                        </Button>
                    </Stack>}
                    <Accordion title={"👉"}
                               description={(isEditMode ? "Configura la risposta quando " : "Quando ") + "viene premuto " + padKey}
                               expanded={openAccordion}
                               onChange={() => setOpenAccordion(!openAccordion)}>
                        <DialplanNode prop={padKey}/>
                    </Accordion>
                </Grid>
            </Paper>
        </Stack>
    );
}

IVRNode.title = "Menù IVR (Premi 1, premi 2, ecc.)"
IVRNode.description = "Usa questo modulo per smistare le telefonate tra più menù"
IVRNode.default = {
    "type": "IVR",
    "announce": {
        "type": "TTS_ANNOUNCE",
        "text": "Premi uno per parlare con il nostro assistente virtuale, premi due per parlare con un operatore",
        "voiceName": "ISABELLA"
    },
    "pad": {}
}


export default IVRNode;
