import * as React from "react";
import { 
    Grid, 
    TextField, 
    MenuItem, 
    IconButton, 
    Box,
    Typography,
    Button,
    FormControl,
    InputLabel,
    Select,
    Chip,
    Divider,
    Stack
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import HttpIcon from '@mui/icons-material/Http';
import CodeIcon from '@mui/icons-material/Code';
import DownArrow from "../DownArrow";
import DialplanNode, { useDialplanNodeContext } from "./DialplanNode";
import { useDialplanEditorContext } from "./DialplanEditorContext";
import TtsAnnounce from "./TtsAnnounce";

const HTTP_METHODS = ['GET', 'POST', 'PUT', 'DELETE'];

// Component for compact view in non-edit mode
function CompactWebhookView({ method, url }) {
    return (
        <Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                Invio di una richiesta verso un servizio esterno
            </Typography>
            
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                p: 1.5, 
                borderRadius: 1, 
                border: '1px dashed rgba(0, 0, 0, 0.12)',
                bgcolor: 'rgba(0, 0, 0, 0.02)'
            }}>
                <Chip 
                    label={method || 'POST'} 
                    color="primary" 
                    size="small" 
                    sx={{ mr: 1.5, fontWeight: 'bold' }}
                />
                <Box>
                    <Typography variant="body2" fontWeight="medium" color="text.primary">
                        {url || 'Nessun URL specificato'}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        {url ? 'Invia dati tramite webhook' : 'Configura l\'URL per attivare il webhook'}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

function Webhook() {
    const { node, setNode } = useDialplanNodeContext();
    const { isEditMode } = useDialplanEditorContext();
    const [headers, setHeaders] = React.useState([]);
    const isUpdatingRef = React.useRef(false);
    
    // Check if any headers are incomplete (missing key or value)
    const hasIncompleteHeaders = React.useMemo(() => {
        return headers.some(header => !header.key.trim() || !header.value.trim());
    }, [headers]);
    
    // Validate JSON body
    const isJsonValid = React.useMemo(() => {
        if (!node.body || node.body.trim() === '') return true;
        try {
            JSON.parse(node.body);
            return true;
        } catch (e) {
            return false;
        }
    }, [node.body]);
    
    // Validate URL (must be a valid HTTP/HTTPS URL)
    const urlValidation = React.useMemo(() => {
        if (!node.url || node.url.trim() === '') {
            return { isValid: false, message: "L'URL è obbligatorio" };
        }
        
        try {
            const url = new URL(node.url);
            if (url.protocol !== 'http:' && url.protocol !== 'https:') {
                return { isValid: false, message: "L'URL deve iniziare con http:// o https://" };
            }
            return { isValid: true, message: "" };
        } catch (e) {
            return { isValid: false, message: "L'URL non è valido" };
        }
    }, [node.url]);
    
    // Initialize headers only once on mount
    React.useEffect(() => {
        if (isUpdatingRef.current) return; // Skip if we're updating
        
        if (!node.headers) {
            setNode({
                ...node,
                headers: { "Content-Type": "application/json" }
            });
            setHeaders([{ key: "Content-Type", value: "application/json" }]);
        } else {
            const pairs = Object.entries(node.headers).map(([key, value]) => ({ key, value }));
            setHeaders(pairs);
        }
    }, []);
    
    // Add new header
    const addHeader = () => {
        const newHeaders = [...headers, { key: '', value: '' }];
        setHeaders(newHeaders);
    };
    
    // Remove header
    const removeHeader = (index) => {
        const newHeaders = [...headers];
        newHeaders.splice(index, 1);
        setHeaders(newHeaders);
        
        // Update node only with valid headers
        updateNodeHeaders(newHeaders);
    };
    
    // Update header field
    const updateHeader = (index, field, value) => {
        const newHeaders = [...headers];
        newHeaders[index] = { ...newHeaders[index], [field]: value };
        setHeaders(newHeaders);
        
        // Update node only with valid headers
        updateNodeHeaders(newHeaders);
    };
    
    // Convert headers array to object and update node
    const updateNodeHeaders = (headersList) => {
        isUpdatingRef.current = true; // Set flag to skip effect
        
        const headersObj = headersList.reduce((obj, { key, value }) => {
            if (key.trim() && value.trim()) {
                obj[key.trim()] = value.trim();
            }
            return obj;
        }, {});
        
        setNode({
            ...node,
            headers: headersObj
        });
        
        // Reset flag after update
        setTimeout(() => {
            isUpdatingRef.current = false;
        }, 0);
    };

    return (
        <>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                        <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                            <HttpIcon sx={{ mr: 1 }} />
                            Webhook
                        </Typography>
                        
                        {isEditMode ? (
                            <>
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                                    Invia una richiesta HTTP a un endpoint esterno per integrare servizi di terze parti.
                                </Typography>

                                <Stack spacing={3}>
                                    {/* Request Configuration Section */}
                                    <Box>
                                        <Stack direction="row" alignItems="center" sx={{ mb: 1.5 }}>
                                            <Typography variant="subtitle1" fontWeight="medium">
                                                Configurazione Richiesta
                                            </Typography>
                                        </Stack>
                                        
                                        <Grid container spacing={2}>
                                            <Grid item xs={8}>
                                                <TextField
                                                    fullWidth
                                                    label="URL"
                                                    value={node.url || ''}
                                                    onChange={(e) => setNode({ ...node, url: e.target.value })}
                                                    placeholder="https://api.example.com/webhook"
                                                    helperText={!urlValidation.isValid ? urlValidation.message : "Inserisci l'URL completo del webhook di destinazione"}
                                                    error={!urlValidation.isValid}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <InputLabel>Method</InputLabel>
                                                    <Select
                                                        value={node.method || 'POST'}
                                                        onChange={(e) => setNode({ ...node, method: e.target.value })}
                                                        label="Method"
                                                    >
                                                        {HTTP_METHODS.map((method) => (
                                                            <MenuItem key={method} value={method}>
                                                                {method}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    
                                    <Divider />
                                    
                                    {/* Body Section */}
                                    <Box>
                                        <Stack direction="row" alignItems="center" sx={{ mb: 1.5 }}>
                                            <CodeIcon sx={{ mr: 1, fontSize: 20, color: 'text.secondary' }} />
                                            <Typography variant="subtitle1" fontWeight="medium">
                                                Corpo della Richiesta
                                            </Typography>
                                        </Stack>
                                        
                                        <TextField
                                            fullWidth
                                            label="Body (JSON)"
                                            value={node.body || ''}
                                            onChange={(e) => setNode({ ...node, body: e.target.value })}
                                            multiline
                                            rows={4}
                                            placeholder="{}"
                                            sx={{ fontFamily: 'monospace' }}
                                            error={!isJsonValid && node.body && node.body.trim() !== ''}
                                        />
                                        {!isJsonValid && node.body && node.body.trim() !== '' ? (
                                            <Typography variant="caption" color="error" sx={{ mt: 0.5, display: 'block' }}>
                                                ⚠️ Il JSON inserito non è valido. Verrà inviato ugualmente, ma potrebbe causare errori nel servizio di destinazione.
                                            </Typography>
                                        ) : (
                                            <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5, display: 'block' }}>
                                                Il corpo della richiesta dovrebbe essere in formato JSON valido
                                            </Typography>
                                        )}
                                        <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5, display: 'block' }}>
                                            Puoi usare il placeholder %CALLER_NUMBER% nel body per inserire il numero del chiamante
                                        </Typography>
                                    </Box>
                                    
                                    <Divider />
                                    
                                    {/* Headers Section */}
                                    <Box>
                                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1.5 }}>
                                            <Typography variant="subtitle1" fontWeight="medium">
                                                Headers
                                            </Typography>
                                            <Button
                                                startIcon={<AddIcon />}
                                                onClick={addHeader}
                                                size="small"
                                                variant="outlined"
                                                disabled={hasIncompleteHeaders}
                                                title={hasIncompleteHeaders ? "Completa i campi esistenti prima di aggiungere un nuovo header" : ""}
                                            >
                                                Aggiungi Header
                                            </Button>
                                        </Stack>
                                        
                                        {headers.length === 0 ? (
                                            <Typography variant="body2" color="text.secondary" sx={{ my: 2 }}>
                                                Nessun header configurato. Clicca su "Aggiungi Header" per aggiungerne uno.
                                            </Typography>
                                        ) : (
                                            <Box sx={{ 
                                                p: 1.5, 
                                                borderRadius: 1, 
                                                border: '1px solid rgba(0, 0, 0, 0.12)',
                                                bgcolor: 'background.paper'
                                            }}>
                                                {headers.map((header, index) => (
                                                    <Grid container spacing={2} key={index} sx={{ mb: index < headers.length - 1 ? 1 : 0 }}>
                                                        <Grid item xs={5}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                label="Key"
                                                                value={header.key}
                                                                onChange={(e) => updateHeader(index, 'key', e.target.value)}
                                                                placeholder="Content-Type"
                                                                error={!header.key.trim() && header.value.trim()}
                                                                helperText={!header.key.trim() && header.value.trim() ? "Campo obbligatorio" : ""}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                label="Value"
                                                                value={header.value}
                                                                onChange={(e) => updateHeader(index, 'value', e.target.value)}
                                                                placeholder="application/json"
                                                                error={header.key.trim() && !header.value.trim()}
                                                                helperText={header.key.trim() && !header.value.trim() ? "Campo obbligatorio" : ""}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <IconButton 
                                                                size="small" 
                                                                onClick={() => removeHeader(index)}
                                                                sx={{ mt: 0.5 }}
                                                                color="error"
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Box>
                                        )}
                                        {hasIncompleteHeaders && (
                                            <Typography variant="caption" color="error" sx={{ mt: 1, display: 'block' }}>
                                                Completa tutti i campi header prima di aggiungerne altri
                                            </Typography>
                                        )}
                                        <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                                            Gli headers permettono di inviare metadati aggiuntivi nella richiesta
                                        </Typography>
                                    </Box>
                                </Stack>
                            </>
                        ) : (
                            <CompactWebhookView method={node.method} url={node.url} />
                        )}
                </Grid>
            </Grid>
            <DownArrow />
            <DialplanNode prop={"next"} />
        </>
    );
}

Webhook.isAdvanced = true;
Webhook.description = "Invia una richiesta HTTP a un endpoint esterno.";
Webhook.title = "Webhook";
Webhook.default = {
    type: "WEBHOOK",
    url: "",
    method: "POST",
    body: JSON.stringify({
        caller_number: "%CALLER_NUMBER%"
    }, null, 2),
    headers: {
        "Content-Type": "application/json"
    },
    next: TtsAnnounce.default
};

export default Webhook; 