import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
  Alert
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDialplanNodeContext } from "./DialplanNode";
import { NODES } from "./Nodes";

const SELECTABLE_NODES = {
  "TIME_SWITCH": NODES.TIME_SWITCH,
  "OPENING_TIME": NODES.OPENING_TIME,
  "TTS_ANNOUNCE": NODES.TTS_ANNOUNCE,
  "RECORD": NODES.RECORD,
  "IVR": NODES.IVR,
  "DIAL": NODES.DIAL,
  "HANGUP": NODES.HANGUP,
  "LIST_ROUTING": NODES.LIST_ROUTING,
  "WEBHOOK": NODES.WEBHOOK,
};

export function DialplanNodeSelection() {
  const [open, setState] = useState(false);
  const { node, setNode } = useDialplanNodeContext();
  const isAdvanced = node && SELECTABLE_NODES[node.type]?.isAdvanced === true;
  const [selectedType, setSelectedType] = useState(node?.type);
  const [selectedAdvanced, setSelectedAdvanced] = useState(isAdvanced);
  
  useEffect(() => {
    if (open) {
      setSelectedType(node?.type);
      setSelectedAdvanced(isAdvanced);
    }
  }, [open, node?.type, isAdvanced]);

  return <Stack direction="row" justifyContent={!node ? "center" : "flex-end"} sx={{mb:2}}>
    <Button color="primary" variant="outlined"
            onClick={() => setState(true)}>{node ? "MODIFICA RISPOSTA" : "AGGIUNGI COMPORTAMENTO"}</Button>
    <Dialog
      open={open}
      onClose={() => setState(false)}
      fullWidth={true}
      maxWidth="sm">
      <DialogTitle>Scegli comportamento</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Seleziona una tipologia di comporamento
        </DialogContentText>
        <FormControl fullWidth={true}>
          <Typography variant="body1" sx={{ mt: 3 }}>
            Comportamento
          </Typography>
          <Select 
            value={selectedType || node?.type} 
            onChange={e => {
              const type = e.target.value;
              setSelectedType(type);
              
              const isNewNodeAdvanced = SELECTABLE_NODES[type]?.isAdvanced === true;
              setSelectedAdvanced(isNewNodeAdvanced);
              
              if (type !== node?.type && !isNewNodeAdvanced) {
                setNode(SELECTABLE_NODES[type]?.default);
                setState(false);
              }
            }}
            sx={selectedAdvanced ? {
              background: 'linear-gradient(90deg, rgba(255,236,210,1) 0%, rgba(252,182,159,1) 100%)',
            } : {}}
          >
            {Object.values(SELECTABLE_NODES).map(n =>
              <MenuItem 
                key={n.default?.type} 
                value={n.default?.type}
                sx={n.isAdvanced ? {
                  background: 'linear-gradient(90deg, rgba(255,236,210,1) 0%, rgba(252,182,159,1) 100%)',
                  fontWeight: 'bold'
                } : {}}
              >
                {n?.title} {n.isAdvanced ? " (Funzione avanzata)" : ""}
              </MenuItem>
            )}
          </Select>
          {selectedAdvanced && (
            <>
              <Alert severity="warning" sx={{ mt: 2 }}>
                <Typography variant="body2" fontWeight="medium">
                  Questa è una funzione avanzata. Se hai bisogno di assistenza nella configurazione, contatta il supporto tecnico.
                </Typography>
              </Alert>
              <Button 
                variant="contained" 
                color="primary" 
                fullWidth 
                sx={{ mt: 2 }}
                onClick={() => {
                  setNode(SELECTABLE_NODES[selectedType]?.default);
                  setState(false);
                }}
              >
                Conferma selezione
              </Button>
            </>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setState(false)}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  </Stack>;
}
