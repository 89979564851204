import * as React from 'react';
import {Chip, FormHelperText, Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DownArrow from "../DownArrow";
import DialTargets from "./DialTargets";
import parseSeconds from "../../utils/parseSeconds";
import AddDialTarget from "./AddDialTarget";
import DialplanNode, {useDialplanNodeContext} from './DialplanNode';
import {useDialplanEditorContext} from './DialplanEditorContext';
import Record from "./Record";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function DialNode() {
    const {node, setNodeChild} = useDialplanNodeContext();
    const {isEditMode} = useDialplanEditorContext();
    return (
        <>
            <Stack>
                <Grid item xs={12}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <Typography variant="h6" align="left">
                            📲 Inoltro ai collaboratori
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sx={{mt: 2}}>
                    <Typography variant="body">
                        Seleziona i collaboratori a cui inoltrare la telefonata, i telefoni possono squillare insieme
                        oppure in sequenza.
                    </Typography>
                </Grid>
                <Grid item container xs={12} sx={{mt: 2, minHeight: 115}}>
                    <DialTargets targets={node.targets} setTargets={setNodeChild("targets")} isEditMode={isEditMode}/>
                </Grid>
            </Stack>

            {isEditMode && <AddDialTarget targets={node.targets} setTargets={setNodeChild("targets")}/>}
            {isEditMode && <EditTimeout timeout={node.timeout} setTimeoutValue={setNodeChild("timeout")} sx={{mt: 4}}/>}
            
            {isEditMode && (
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={true}
                            checked={node.musicOnHold || true}
                            onChange={(e) => setNodeChild("musicOnHold")(e.target.checked)}
                        />
                    }
                    label="Riproduci musica di attesa mentre la chiamata è in coda"
                    sx={{ 
                        mt: 1, 
                        mb: 3, 
                        display: 'flex',
                        alignItems: 'center'
                    }}
                />
            )}

            <TimeoutChip timeout={node.timeout}/>
            <DownArrow/>
            <DialplanNode prop={"next"}/>
        </>
    )
}

function EditTimeout({timeout, setTimeoutValue, sx}) {
    return (
        <Stack sx={sx}>
            <Typography variant="body">
                Seleziona il tempo massimo di attesa prima di considerare la telefonata come non risposta.
            </Typography>
            <FormControl fullWidth sx={{my: 2}}>
                <InputLabel>Tempo massimo di attesa</InputLabel>
                <Select
                    value={timeout}
                    label="Tempo massimo di attesa"
                    displayEmpty={true}
                    renderValue={(value) => parseSeconds(value)}
                    onChange={event => setTimeoutValue(event.target.value)}>
                    <MenuItem value={20}>20 secondi</MenuItem>
                    <MenuItem value={30}>30 secondi</MenuItem>
                    <MenuItem value={40}>40 secondi</MenuItem>
                    <MenuItem value={60}>1 minuto</MenuItem>
                </Select>
                <FormHelperText>Se nessuno risponde, la telefonata passa allo step successivo.</FormHelperText>
            </FormControl>
        </Stack>
    );
}

function TimeoutChip({timeout}) {
    return (
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <Chip color="warning" label={"Se nessuno risponde entro " + parseSeconds(timeout)}/>
        </div>
    );
}

DialNode.description = "Seleziona i collaboratori a cui inoltrare la telefonata, i telefoni possono squillare insieme oppure in sequenza.";
DialNode.title = "Inoltra chiamata ai collaboratori"
DialNode.default = {
    type: "DIAL",
    targets: [],
    timeout: 60,
    musicOnHold: true,
    next: {
        type: "TTS_ANNOUNCE",
        text: "I nostri operatori sono al momento impegnati, lascia un messaggio dopo il beep grazie.",
        voiceName: "ISABELLA",
        next: Record.default
    }
}


export default DialNode;
