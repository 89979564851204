import * as React from 'react';
import { Grid, TextField, Typography, Paper, IconButton, FormControl, Select, MenuItem, Button, useTheme, useMediaQuery } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import PreviewIcon from '@mui/icons-material/Preview';
import { useAudioPlayer } from './AudioPlayerContext';
import { useTtsPreview } from '../../service/TtsService';

function AnnounceBox({ 
    title, 
    description, 
    isEditMode, 
    text, 
    onTextChange, 
    wavPlayUrl,
    voiceName,
    onVoiceChange
}) {
    const { playAudio, stopAudio, isUrlPlaying } = useAudioPlayer();
    const { mutate: generatePreview, isLoading } = useTtsPreview();
    const [previewUrl, setPreviewUrl] = React.useState(wavPlayUrl);
    const [lastGeneratedText, setLastGeneratedText] = React.useState(text);
    const [lastGeneratedVoice, setLastGeneratedVoice] = React.useState(voiceName);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isPlaybackIssue, setIsPlaybackIssue] = React.useState(false);
    
    // Check if this component's audio is playing
    const isThisPlaying = previewUrl ? isUrlPlaying(previewUrl) : false;

    // Detect browsers with playback issues (Safari and iPhone)
    React.useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isPlaybackSupported = !(/^((?!chrome|android).)*safari/i.test(userAgent) || /iphone/i.test(userAgent));
        setIsPlaybackIssue(!isPlaybackSupported);
    }, []);

    // Reset preview URL when text or voice changes
    React.useEffect(() => {
        if (text !== lastGeneratedText || voiceName !== lastGeneratedVoice) {
            setPreviewUrl(null);
        }
    }, [text, lastGeneratedText, voiceName, lastGeneratedVoice]);

    const handlePlayAudio = () => {
        if (previewUrl) {
            if (isThisPlaying) {
                stopAudio();
            } else {
                playAudio(previewUrl);
            }
        }
    };

    const handlePreview = () => {
        // If we have a preview URL and neither text nor voice have changed, use it
        if (previewUrl && text === lastGeneratedText && voiceName === lastGeneratedVoice) {
            if (isThisPlaying) {
                stopAudio();
            } else {
                playAudio(previewUrl);
            }
            return;
        }

        // Otherwise generate a new preview
        generatePreview(
            { text, voiceName },
            {
                onSuccess: (data) => {
                    if (data.audioPlayUrl) {
                        setPreviewUrl(data.audioPlayUrl);
                        setLastGeneratedText(text);
                        setLastGeneratedVoice(voiceName);
                        playAudio(data.audioPlayUrl);
                    }
                }
            }
        );
    };

    return (
        <>
            <Grid item xs={12}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <Typography variant="h6" align="left">
                        {title}
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="body" display={'inline'}>
                    {description}
                </Typography>
            </Grid>
            <Grid item container sx={{ mt: 2, mb: 2 }}>
                {isEditMode ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Testo annuncio"
                                multiline
                                rows={isMobile ? 3 : 4}
                                value={text}
                                onChange={onTextChange}
                                variant="outlined"
                                color={"primary"}
                            />
                            {!isPlaybackIssue && (
                                <Typography 
                                    variant="body2" 
                                    color={isLoading ? "text.disabled" : "primary"}
                                    onClick={!isLoading ? handlePreview : undefined}
                                    sx={{ 
                                        cursor: isLoading ? 'default' : 'pointer',
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: 'fit-content',
                                        mt: 1,
                                        '&:hover': {
                                            color: isLoading ? "text.disabled" : 'primary.dark',
                                        }
                                    }}
                                >
                                    {isThisPlaying ? (
                                        <PauseIcon fontSize="small" sx={{ mr: 0.5 }} />
                                    ) : (
                                        <PlayArrowIcon fontSize="small" sx={{ mr: 0.5, opacity: isLoading ? 0.5 : 1 }} />
                                    )}
                                    {isLoading 
                                        ? 'Generazione in corso...' 
                                        : isThisPlaying 
                                            ? 'In riproduzione... clicca per fermare' 
                                            : 'Ascolta anteprima del messaggio'
                                    }
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="flex-end">
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Typography variant="body1" sx={{ mb: 1 }}>
                                            Quale voce vuoi utilizzare?
                                        </Typography>
                                        <Select value={voiceName} onChange={onVoiceChange}>
                                            <MenuItem value={"ISABELLA"}>👩 Isabella</MenuItem>
                                            <MenuItem value={"DIEGO"}>👨 Diego</MenuItem>
                                            <MenuItem value={"ANNA"} sx={{ 
                                                background: 'linear-gradient(to right, rgba(144, 202, 249, 0.1), rgba(144, 202, 249, 0.2))',
                                                position: 'relative',
                                                '&::after': {
                                                    content: '"NUOVO"',
                                                    position: 'absolute',
                                                    right: '8px',
                                                    fontSize: '10px',
                                                    padding: '1px 5px',
                                                    borderRadius: '4px',
                                                    backgroundColor: 'primary.main',
                                                    color: 'white',
                                                }
                                            }}>
                                                👩‍🦰 Anna <Typography component="span" variant="caption" color="text.secondary" sx={{ ml: 1, fontStyle: 'italic' }}>- Voce AI</Typography>
                                            </MenuItem>
                                        </Select>
                                        <Typography variant="caption" color="primary" sx={{ mt: 0.5, display: 'block', fontWeight: 'medium', fontSize: '0.8rem' }}>
                                            ✨ Prova la nuova voce Anna
                                        </Typography>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Paper variant="outlined" sx={{ p: 2, width: "100%" }}>
                        <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                            {previewUrl && !isPlaybackIssue && (
                                <IconButton 
                                    onClick={handlePlayAudio} 
                                    color="primary" 
                                    size="medium"
                                    sx={{ 
                                        backgroundColor: isThisPlaying ? 'primary.light' : 'white',
                                        border: '1px solid',
                                        borderColor: 'primary.main',
                                        color: isThisPlaying ? 'white' : 'primary.main',
                                        '&:hover': {
                                            backgroundColor: 'primary.light',
                                            color: 'white',
                                        },
                                        animation: isThisPlaying ? 'pulse 1.5s infinite' : 'none',
                                        '@keyframes pulse': {
                                            '0%': { boxShadow: '0 0 0 0 rgba(25, 118, 210, 0.4)' },
                                            '70%': { boxShadow: '0 0 0 6px rgba(25, 118, 210, 0)' },
                                            '100%': { boxShadow: '0 0 0 0 rgba(25, 118, 210, 0)' }
                                        }
                                    }}
                                >
                                    {isThisPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                                </IconButton>
                            )}
                            <Typography variant="body2" sx={{ flex: 1, pt: (previewUrl && !isPlaybackIssue) ? 1 : 0 }}>
                                {text}
                            </Typography>
                        </div>
                    </Paper>
                )}
            </Grid>
        </>
    );
}

export default AnnounceBox; 